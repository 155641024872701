<template>
  <div>
    <Header v-if="isMobile" />
    <div
      :class="{
        'show-navbar': showFullNav,
        'grid-container--list-view': showListView
      }"
      class="grid-container has-[#chatLayout]:overflow-y-hidden h-screen grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] grid-rows-[100px_repeat(auto-fill,_minmax(min(100px,_100%),_1fr))] grid-flow-row desktop:grid-cols-[60px_190px_repeat(auto-fill,_minmax(250px,_1fr))]"
    >
      <div
        :id="`${isChatRoute ? 'chatLayout' : 'mainLayout'}`"
        class="col-[1_/_-1] row-start-1 row-end-[-1] h-auto overflow-auto overscroll-contain max-h-full desktop:row-start-1 desktop:row-end-[-1] desktop:col-[2_/_-1]"
        :class="{
          'pt-[120px] px-4 pb-0 tabletLandscape:pt-[30px] tabletLandscape:px-[30px] tabletLandscape:pb-0 desktop:!pt-5 desktop:!px-6 desktop:pb-0':
            !isChatRoute,
          'desktop:!col-[3_/_-1]': !isChatRoute && showFullNav,
          listView: showListView,
          '!bg-white': applyWhiteBg,
          '!pb-20':
            !isChatRoute &&
            route.name !== 'clientRevisionEditor' &&
            route.meta.baseRoute !== 'clientPhraseConnector' &&
            route.name !== 'clientAutomationFlow' &&
            !isLangAssetsRoute &&
            route.name !== 'embeddingProjector',
          '!p-0 h-screen tabletPortrait:!overflow-hidden bg-white':
            route.meta.baseRoute === 'clientPhraseConnector',
          '!pb-4': isLangAssetsRoute,
          '[&>div]:h-full': route.name === 'clientSubaccountsUsage'
        }"
      >
        <router-view v-slot="{ Component }">
          <transition name="slide-fade">
            <component :is="Component" v-if="isStateFetched" />
          </transition>
        </router-view>
      </div>
      <Navigation
        id="navigation"
        :expanded="expandNavbar"
        class="h-screen hidden z-[99] grid-cols-[60px_190px] desktop:grid"
        :class="{ 'expanded !grid-cols-[250px]': expandNavbar }"
      />
    </div>
    <div
      v-if="showChatCookies"
      class="absolute right-4 bottom-4 flex items-center z-[10000]"
    >
      <div class="w-[48px] h-[48px] bg-primary-80 rounded-full mr-3" />
      <div class="w-[60px] h-[60px] bg-primary-80 rounded-full" />
    </div>
  </div>
</template>
<script setup>
import { TokenService } from '@/api/new/services/storage.service'
import Header from '@/components/header/Header'
import Navigation from '@/components/navbar/Navigation'
import i18n from '@/i18n'
import { $cookies, $echo } from '@/main'
import moment from 'moment'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const google = window.google
const store = useStore()
const $t = i18n.t
const route = useRoute()
const router = useRouter()

// DECLARE REFS (FORMERLY DATA OBJECT):

const isStateFetched = ref(false)
const isMobile = ref(false)

// MAP STATE MODULES:

const shouldRefreshPage = computed(
  () => store.state.workspace.shouldRefreshPage
)
const getCurrentUser = () => store.dispatch('workspace/getCurrentUser')
const getProjects = (payload) =>
  store.dispatch('workspace/getProjects', payload)
const getFolders = () => store.dispatch('folder/getFolders')
const getCustomerAccount = () => store.dispatch('account/getCustomerAccount')
const getSettings = () => store.dispatch('account/getSettings')
const getCustomerCompanyDetail = (payload) =>
  store.dispatch('teams/getCustomerCompanyDetail', payload)
const startLoader = () => store.dispatch('loading/startLoader')
const stopLoader = () => store.dispatch('loading/stopLoader')
const getChannels = () => store.dispatch('chat/getChannels')
const setCurrentAccountById = (payload) =>
  store.dispatch('workspace/setCurrentAccountById', payload)
const addAccessRoutes = () => store.dispatch('navigation/addAccessRoutes')
const error = (payload) => store.dispatch('toast/error', payload)
const addMessage = (payload) => store.dispatch('chat/addMessage', payload)
const unhideConversation = (payload) =>
  store.dispatch('chat/unhideConversation', payload)
const getNotification = (payload) =>
  store.dispatch('chatNotification/getNotification', payload)
const getTranslators = (payload) =>
  store.dispatch('translators/getTranslators', payload)
const toggleG2ReviewModal = () => store.commit('modals/toggleG2ReviewModal')
const toggleAiIntroModal = () => store.commit('modals/toggleAiIntroModal')
const markG2SeenFormAt = (payload) =>
  store.dispatch('G2Review/markG2SeenFormAt', payload)
const getG2State = () => store.dispatch('G2Review/getG2State')

const setListView = (payload) => store.commit('account/setListView', payload)
const updateMessage = (payload) => store.commit('chat/updateMessage', payload)
const removeMessage = (payload) => store.commit('chat/removeMessage', payload)
const setRefreshPage = (payload) =>
  store.commit('workspace/setRefreshPage', payload)

const showListView = computed(() => store.state.account.showListView)
const showNav = computed(() => store.state.navigation.showNav)
const baseRoute = computed(() => store.state.navigation.baseRoute)
const showVerifyMfaModal = computed(() => store.state.modals.showVerifyMfaModal)
const currentUser = computed(() => store.state.workspace.currentUser)
const verifiedMfa = computed(() => store.state.workspace.verifiedMfa)
const currentConversation = computed(() => store.state.chat.currentConversation)
const freemiumAccount = computed(() => store.getters['account/freemiumAccount'])
const isOnProduction = computed(() => store.getters['account/isOnProduction'])
const isAdmin = computed(() => store.getters['workspace/isAdmin'])
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
const allConversations = computed(() => store.getters['chat/allConversations'])
const isValidAccountId = store.getters['workspace/isValidAccountId']
const showSubnav = computed(() => store.getters['navigation/showSubnav'])

// COMPUTED:

const isChatRoute = computed(() => {
  return route.name === 'customerChat'
})

const isBuildTeamRoute = computed(() => {
  return route.meta.breadcrumb.some(
    (b) => b.name === $t('customer.nav.translators.breadcrumb')
  )
})

const isLangAssetsRoute = computed(() => {
  return (
    baseRoute.value == 'languageAssets' ||
    baseRoute.value == 'importLanguageAssets'
  )
})

const expandNavbar = computed(() => {
  if (
    baseRoute.value == 'contentGenerationV2' ||
    baseRoute.value == 'clientPhraseConnector' ||
    baseRoute.value == 'embedding'
  )
    return false
  return showNav.value && !showSubnav.value
})

const showFullNav = computed(() => {
  if (
    baseRoute.value == 'contentGenerationV2' ||
    baseRoute.value == 'embedding'
  )
    return false
  return showNav.value
})

const applyWhiteBg = computed(() => {
  return (
    route.name == 'clientUsage' ||
    route.name == 'clientRevisionEditor' ||
    route.name === 'embeddingProjector' ||
    isLangAssetsRoute.value ||
    isBuildTeamRoute.value
  )
})

const showChatCookies = computed(() => {
  return process.env.VUE_APP_ENV === 'local'
})

// METHODS:

function onBrowserSize() {
  if (window.innerWidth >= 992) {
    isMobile.value = false
    setListView(JSON.parse($cookies.get('showListView')))
  } else {
    isMobile.value = true
    setListView(false)
  }
}

async function chatListener(conversation) {
  if (process.env.VUE_APP_ENV === 'local') return
  $echo
    .private(`chat-channel.${conversation.id}`)
    .listen('.message.created', async (payload) => {
      const sender = payload.attributes.sender
      const channelId = payload.attributes.channel_id
      if (currentConversation.value.id === channelId) {
        addMessage(payload)
      } else {
        await unhideConversation(channelId)
      }
      if (sender.id !== currentUser.value.id) {
        if (
          currentConversation.value &&
          Object.keys(currentConversation.value).length > 0 &&
          channelId === currentConversation.value.id
        )
          return
        const channel = allConversations.value.find((c) => c.id === channelId)
        getNotification({
          title: `${$t('label.notification.title')} ${
            channel && channel.attributes.display_name !== null
              ? `#${channel.attributes.display_name}`
              : `${sender.attributes.first_name} ${sender.attributes.last_name}`
          }`,
          message: payload.attributes.body,
          channelId: channelId
        })
      }
    })
    .listen('.message.updated', (payload) => {
      updateMessage(payload)
    })
    .listen('.message.deleted', (payload) => {
      removeMessage(payload)
    })
}
async function inviteChannelListener(id) {
  if (process.env.VUE_APP_ENV === 'local') return
  $echo
    .private(`chat-member-customer.${id}`)
    .listen('.channel.invitation', async (payload) => {
      await getChannels()
      if (payload.inviter_member.id !== id) {
        let message = ''
        if (payload.channel.attributes.type === 'channel') {
          message = `${$t('label.notification.invite_label')} #${
            payload.channel.attributes.display_name
          }`
        } else {
          if (payload.inviter_member.attributes) {
            message = `${$t('label.notification.invite_dms_label')} ${
              payload.inviter_member.attributes.first_name
            } ${payload.inviter_member.attributes.last_name}`
          }
        }
        getNotification({
          title: $t('label.notification.invite_title'),
          message: message,
          channelId: payload.channel.id
        })
      }
    })
}

async function initCustomerSide() {
  startLoader()
  if (shouldRefreshPage.value) {
    setRefreshPage(false)
    window.location.reload()
  }
  try {
    $cookies.set('team_identifier', route.params.teamIdentifier)
    await getCurrentUser()
    if (
      !showVerifyMfaModal.value &&
      !isValidAccountId(route.params.teamIdentifier)
    ) {
      if (isAuthenticated.value && isAdmin.value) {
        stopLoader()
        router.push({ name: 'accountsList' })
        return
      }
      TokenService.removeAll()
      stopLoader()
      error({
        title: $t('customer.toast.error.invalid_account_title'),
        message: $t('customer.toast.error.invalid_account', {
          account: route.params.teamIdentifier
        })
      })
      router.push({ name: 'BaseLogin' })
      return
    }
    await setCurrentAccountById(route.params.teamIdentifier)
    await getCustomerAccount()
    await getProjects({
      search: route.query.search,
      currentPage: route.query.page,
      perPage: $cookies.get('perPage') || route.query.perPage
    })
    await getFolders()
    await getSettings()
    try {
      await getCustomerCompanyDetail({
        customerId: currentUser.value.id
      })
    } finally {
      addAccessRoutes()
    }
    await getTranslators({ page: route.query.page || 1 })
    await getChannels()
    // identify user on hubspot for tracking purposes
    if (isOnProduction.value) {
      const timestamp = Date.now()
      const _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: currentUser.value.attributes.email,
          recent_platform_log_in_date: timestamp
        }
      ])
      _hsq.push(['trackPageView'])
    }
  } catch {
    stopLoader()
  } finally {
    isStateFetched.value = true
    await getG2State()
    if (
      currentUser.value.attributes.meta.g2.login_count > 5 &&
      currentUser.value.attributes.meta.g2.seen_form_at == null &&
      isAdmin.value == false
    ) {
      toggleG2ReviewModal()
      markG2SeenFormAt(moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'))
    }
    if (freemiumAccount.value && !$cookies.get('aiIntro')) {
      toggleAiIntroModal()
      $cookies.set('aiIntro', 'true')
    }
    stopLoader()
  }
}

onMounted(() => {
  initCustomerSide()
  window.addEventListener('load', onBrowserSize)
  window.addEventListener('resize', onBrowserSize)
  if (google) {
    google.accounts.id.cancel()
  }
  isMobile.value = window.innerWidth < 992
})

onUnmounted(() => {
  if (allConversations.value.length > 0) {
    allConversations.value.forEach((conversation) =>
      $echo
        .private(`chat-channel.${conversation.id}`)
        .stopListening('.message.created')
    )
    $echo
      .private(`chat-member-customer.${currentUser.value.id}`)
      .stopListening('.channel.invitation')
  }
})

// WATCHERS:

watch(
  () => route.params.teamIdentifier,
  async () => {
    if (route.meta.isPublicRoute) {
      return
    }
    if (isAdmin.value) {
      router.push({ name: 'accountsList' })
      return
    }
    $cookies.set('team_identifier', route.params.teamIdentifier)
    if (isAdmin.value) {
      router.push({ name: 'accountsList' })
      return
    }
    $cookies.set('team_identifier', route.params.teamIdentifier)
    startLoader()
    try {
      await setCurrentAccountById(route.params.teamIdentifier)
      await getCustomerAccount()
      await getProjects({
        search: null,
        currentPage: 1,
        perPage: $cookies.get('perPage') || 15
      })
      await getFolders()
    } catch {
      stopLoader()
      TokenService.removeAll()
      router.push({ name: 'BaseLogin' })
    } finally {
      addAccessRoutes()
      stopLoader()
    }
    // identify user on hubspot for tracking purposes
    if (isOnProduction.value) {
      const _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: currentUser.value.attributes.email
        }
      ])
      _hsq.push(['trackPageView'])
    }
  }
)

watch(
  () => allConversations.value.length,
  () => {
    if (allConversations.value.length > 0) {
      allConversations.value.forEach((conversation) =>
        $echo
          .private(`chat-channel.${conversation.id}`)
          .stopListening('.message.created')
      )
      allConversations.value.forEach((conversation) =>
        chatListener(conversation)
      )
    }
  },
  { immediate: true }
)

watch(
  () => currentConversation.value.id,
  () => {
    if (!allConversations.value.includes(currentConversation.value)) {
      chatListener(currentConversation.value)
    }
  }
)

watch(
  () => currentUser.value.id,
  (newId) => {
    if (newId) {
      $echo
        .private(`chat-member-customer.${newId}`)
        .stopListening('.channel.invitation')
      inviteChannelListener(newId)
    }
  }
)
watch(
  () => verifiedMfa.value,
  (value) => {
    if (value) {
      initCustomerSide()
    }
  }
)

watch(
  () => route.query,
  () => {
    if (process.env.VUE_APP_ENV === 'prod') {
      const _hsq = (window._hsq = window._hsq || [])
      _hsq.push(['setPath', route.fullPath])
      _hsq.push(['trackPageView'])
    }
  }
)
</script>
<style lang="scss">
@use '../../styles/mixins/mixins' as *;

.grid-container {
  grid-template-areas:
    'main main main main main'
    'main main main main main'
    'main main main main main'
    'main main main main main';
}

@include desktop {
  .grid-container {
    grid-template-areas:
      'navigation . main main main'
      'navigation . main main main'
      'navigation . main main main'
      'navigation . main main main';
    &.show-navbar {
      grid-template-areas:
        'navigation navigation main main main'
        'navigation navigation main main main'
        'navigation navigation main main main'
        'navigation navigation main main main';
    }
  }
}
</style>
